.frame {
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: center;
  padding: 0 48px;
  margin: 0 auto;
}

@media (max-width: 500px) {
  & .frame {
    width: auto;
    padding: 16px;
  }
}

:global(.broker) {
  background-color: #FBFBFB;

  & .container {
    background-color: transparent;
  }

  @media (min-width: 750px) {
    & .container {
      overflow-y: unset;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

:global(#headline.desktop-show.default-hidden).header {
  display: flex;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  overflow-wrap: break-word;
}

:global(h1#headline).header {
  margin-bottom: 56px;
}

.header span {
  color: #1D4A73;
  text-align: center;
  font-family: Arial;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.96px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.flowButton {
  display: flex;
  align-items: center;
  border-radius: 24px;
  background: #3375B1;
  align-self: stretch;
}

.flowButton a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 12px 24px;
  gap: 16px;
  align-self: stretch;
  text-decoration: none;
}

.flowImage {
  display: flex;
  height: 36px;
  padding: 2px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.flowImage svg {
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.flowButton a span {
  color: var(--Brand-color-White, #FFF);
  background-color: transparent;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.logo {
  width: 142.105px;
  height: 36px;
}