:global(body.screen-dutchidin-selectbank) {
  background: #FBFBFB;
}

:global(.broker) {
  & .frame {
    padding: 0px 24px;
    background: transparent;
  }
  @media (min-width: 750px) {
    & .frame {
      overflow-y: unset;
      padding: 0px;
    }
  }
}

:global(.broker #headline) {
  &.header {
    display: block;
    margin-top: 24px;
    margin-bottom: 32px;

    color: #000;

    text-align: center;
    font-family: "IBM Plex Sans";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 37.2px */
    letter-spacing: -0.9px;
  }
  @media (min-width: 750px) {
    &.header {
      margin-top: 48px;
    }
  }
}

.banks {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.bank {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(50% - 4px);

  height: 60px;
  padding: 0px 24px;
  box-sizing: border-box;

  border-radius: 2px;
  background: #FFF;

  /* eID & bank btn dropshadow */
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);

  &:hover {
    background: rgba(96, 79, 237, 0.10)
  }
  &:focus, &:active, &:focus-visible {
    background: #FFF;
    box-shadow: 0px 0px 0px 4px rgba(0, 9, 129, 0.14);
    outline: none;
  }
}

.cancel {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .button {
    display: flex;
    height: 40px;
    box-sizing: border-box;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    border: 1px solid rgba(90, 97, 146, 0.30);
    box-shadow: 0px 1px 2px 0px rgba(38, 46, 82, 0.05);

    color: #424974;
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; 

    &:hover {
      background: rgba(96, 79, 237, 0.10)
    }
    &:focus, &:active, &:focus-visible {
      background: #FFF;
      box-shadow: 0px 0px 0px 4px rgba(0, 9, 129, 0.14);
      outline: none;
    }
  }
}