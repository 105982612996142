.broker.broker-select-login .frame {
    color: #716f6c;
  }
  
  .broker.broker-select-login .frame .content {
    padding: 25px;
  }
  
  .broker.broker-select-login .frame a {
    color: #666461;
  }
  
  .broker .button {
    font-size: 18px;
    font-weight: 400;
  }
  
  .broker.broker-mitid #frame {
    display: flex;
    justify-content: center;
    flex-flow: column;
  }

  @media (min-width: 750px) {
    .broker.broker-mitid #frame {
        max-width: 416px;
    }
  }

  .broker.broker-mitid.screen-danishmitid-brokerlandingpage #frame {
    background: transparent;
  }

  .broker.broker-mitid #coreClientParent {
    display: block;
    min-height: 588px;
    box-shadow: 0px 0px 10px 0px rgba(12, 12, 12, 0.10);
    margin: 8px 8px 8px 8px;
  }
  
  .broker.broker-select-login .button-login {
    display: block;
    width: 100%;
    margin-bottom: 22px;
    height: 60px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    border: 0;
    color: #FFF;
    cursor: pointer;
    text-decoration: none;
    box-sizing: border-box;
  }
  
  .broker.broker-select-login .button-login .logo {
    width: 160px;
    height: 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .broker.broker-select-login .button-login .text {
    padding-left: 15px;
  }
  
  .broker.broker-select-login .button-vipps {
    background-color: #e26d38;
  }
  .broker.broker-select-login .button-vipps .logo {
    border-color: #e68255;
  }
  
  .broker.broker-select-login .button-dknemid {
    background-color: #447677;
  }
  .broker.broker-select-login .button-dknemid .logo {
    border-color: #618b8c;
  }
  
  .broker.broker-select-login .button-sebankid {
    background-color: #5b99b9;
  }
  
  .broker.broker-select-login .button-sebankid .logo {
    border-color: #73a9c4;
  }
  
  .broker.broker-select-login .button-nobankid {
    background-color: #39134d;
  }
  
  .broker.broker-select-login .button-nobankid .logo {
    border-color: #563767;
  }
  
  .broker .frame.with-header {
    border-top-left-radius: 7.5px;
    border-top-right-radius: 7.5px;
  }
  
  .broker .frame.with-header header {
      height: 73px;
      border-radius: 7.5px;
      border: 1px solid #e0dad3;
      background-color: #e2ded9;
      font-size: 28px;
      font-weight: 300;
      line-height: 25px;
      color: #666461;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .broker .frame {
    min-height: auto;
  }
  
  
  @media (min-width: 750px) {
      .broker.broker-mitid .frame {
          overflow-y: hidden;
      }
  }
  .broker.broker-mitid .mitid-border {
    padding: 10px;
  }
  @media (min-width: 750px) {
    .broker.broker-mitid .mitid-border {
        border: 1px solid #ccc;
        padding: 31px;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }
  }
  
  .mitid-border.centered-horizontal {
      display: flex;
      justify-content: center;
      flex-flow: column;
  }
  
  @media (min-width: 750px) {
      .dkmitid .dkmitid-cvr-select {
          height: auto;
          min-height: auto;
      }
  }
  
  .broker.broker-mitid .form-group label, .broker.broker-mitid .form-group input, .broker.broker-mitid .button {
      font-family: "IBM Plex Sans",Arial,Helvetica,FreeSans,sans,sans-serif;
  }
  
  .broker.broker-mitid .frame-error p, .broker.broker-mitid .frame-error label {
      width: 100%;
      font-family: "IBM Plex Sans",Arial,Helvetica,FreeSans,sans,sans-serif;
  }
  
      .broker.broker-mitid .frame-error p.error {
          color: red;
      }
      .broker.broker-mitid .frame-error p.trace {
        color: grey;
        font-size: 80%;
    }
  
      .broker.broker-mitid .form-group .input-text {
          border-radius: .25rem;
          outline: none;
          height: 48px;
          width: 100%;
          padding: 0 1rem;
          font-weight: 500 !important;
          font-size: 1rem;
          letter-spacing: 0;
          border: 1px solid rgba(0, 0, 0, .4);
          box-sizing: border-box;
          color: #333;
          margin-bottom: .8rem;
      }
  
      .broker.broker-mitid .form-group .input-text:hover {
          border: 2px solid #0060e6 !important;
          position: relative;
          right: 1px;
      }
  
      .broker.broker-mitid .form-group .input-text:focus {
          background: rgba(0,96,230,.05) !important;
          border: 2px solid #0060e6 !important;
          position: relative;
          right: 1px;
      }
  
  .broker.broker-mitid .form-group label {
      display: inline-block;
      font-weight: 700;
      margin-bottom: 8px;
      font-size: .875rem;
      line-height: 24px;
      text-transform: uppercase;
      letter-spacing: .2px;
      color: #333;
  }
  
  .broker.broker-mitid .form-group.checkbox {
      display: flex;
      gap: 8px;
  }
  
  .broker.broker-mitid .form-group .input-checkbox {
      width: 18px;
      height: 18px;
      display: inline-block;
      border: #001c44 solid 2px;
      border-radius: 3px;
      box-sizing: border-box;
  }
  
  
  .broker.broker-mitid .button {
      position: relative;
      width: 100%;
      cursor: pointer;
      letter-spacing: 0;
      line-height: 1.5rem;
      border-radius: 6px;
      border: none;
      display: inline-flex;
      align-items: center;
      text-align: center;
      box-sizing: border-box;
      -webkit-flex-shrink: 0;
      outline: none;
      font-weight: 600;
      font-size: .875rem;
      vertical-align: middle;
      margin-bottom: .8rem;
      padding: .25rem .75rem .25rem 1rem;
      text-transform: uppercase;
      letter-spacing: .8px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-radius: 6px;
      height: 3rem;
      font-weight: bold;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      text-decoration: none;
  }
  
  
  .broker.broker-mitid .button-primary {
      background: #0060e6;
      color: #fff;
  }
  
      .broker.broker-mitid .button-primary svg {
          fill: #fff;
      }
  
      .broker.broker-mitid .button-primary:hover {
          background: #004cb8;
      }
  
      .broker.broker-mitid .button-primary:disabled, .broker.broker-mitid .button-primary:disabled:hover {
          background: #e5e5e5;
          color: #fff;
      }
  
  .broker.broker-mitid .button-default {
      background: #FFF;
      outline: 1px solid #919191;
      border-radius: 4px;
      color: #071B41;
      text-transform: none;
      padding: 11px 17px;
      height: auto;
      min-height: 3rem;
      text-align: left;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      margin: 0;
      gap: 16px;
  }
      .broker.broker-mitid .button-default .text {
          flex-grow: 1;
      }
  
      .broker.broker-mitid .button-default svg {
          color: #071B41;
          fill: #071B41;
      }
  
      .broker.broker-mitid .button-default:hover {
          color: #0060E6;
          outline: 2px solid #0060E6;
      }
  
          .broker.broker-mitid .button-default:hover svg {
              color: #0060E6;
              fill: #0060E6;
          }
  
      .broker.broker-mitid .button-default:disabled, .broker.broker-mitid .button-default:disabled:hover {
          background: #e5e5e5;
          color: #fff;
      }
  
  .dkmitid-cvr-select p {
      margin-bottom: 8px;
  }
  .dkmitid-cvr-select .dkmitid-cvr-select-groups {
      display: flex;
      flex-flow: column;
      gap: 24px;
  }
  
  .broker.broker-mitid .dkmitid-cpr-form .form-group.checkbox {
      display: flex;
      flex-flow: row;
      align-items: center;
      position: relative;
  }
  
  .broker.broker-mitid .form-group.checkbox label {
      display: inline-block;
      margin-bottom: 0;
      font-weight: 500;
      color: #001c44;
      text-transform: none;
      font-size: 16px;
      position: relative;
      top: -1px;
  }
  
  .broker.broker-mitid .form-group.checkbox .input-checkbox {
      opacity: 0;
      cursor: pointer;
      position: absolute;
      top: 0px;
      left: 0px;
  }
  
  .broker.broker-mitid .form-group.checkbox .input-checkbox + .checkmark {
      width: 18px;
      height: 18px;
      display: block;
      border: #001c44 solid 2px;
      border-radius: 3px;
      box-sizing: border-box;
      margin-left: 2px;
      margin-right: 13px;
  }
  
  .broker.broker-mitid .form-group.checkbox .input-checkbox:hover + .checkmark {
      border-color: #0060e6;
  }
  
  .broker.broker-mitid .form-group.checkbox .input-checkbox:checked + .checkmark {
      background-image: url(./images/checkbox.svg);
      background-size: 18px 18px;
      border: none !important;
  }
  
  ul.company-list {
      list-style-type: none;
      margin: 0px 0px 0px 0px;
      padding: 0px;
      display: flex;
      flex-flow: column;
      gap: 10px;
  }
  
  .dkmitid .dkmitid-cvr-select p {
      font-family: "IBM Plex Sans",Arial,Helvetica,FreeSans,sans,sans-serif;
  }
  
  .broker-mitid.appswitch-done p {
      font-family: "IBM Plex Sans",Arial,Helvetica,FreeSans,sans,sans-serif;
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      padding: 10px;
  }
  
  .broker-mitid.appswitch-done #frame {
      display: flex;
      align-items: center;
      justify-content: center;
  }