.terms {
  color: #5A6192;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;

  max-width: 400px;
  margin: 0px auto 0px auto;
  padding: 14px 0px;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 12px;
}

.terms .links {
  margin-top: 7px;
}

.terms a, .terms a:visited {
  text-decoration: underline;
  color: #5A6192;
}