.tabs {
  border-bottom: 2px solid transparent;
  display: flex;
  flex-flow: row;
  gap: 8px;
  padding-top: 12px;
  margin: 0px 8px -8px 8px;
  z-index: 10;
}

.tab {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  margin-bottom: -2px;
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  padding: 0px 16px 7px 16px;

  &.active {
    border-bottom: 2px solid #0060E6;
  }
}

.localidp img {
  height: 11.28px;
}
.mitid img {
  height: 15.838px;
}