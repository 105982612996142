.wrapper {
  background: white;
  padding: 24px 16px;
  gap: 16px;
  display: flex;
  flex-flow: column;
  margin: 8px 8px 8px 8px;

  box-shadow: 0px 0px 10px 0px rgba(12, 12, 12, 0.10);
}

.header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-end;

  & img {
    height: 18px;
    margin-bottom: 3px;
  }

  & h2 {
    font-family: "IBM Plex Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    color: #000;
    margin: 0;
    padding: 0;
  }
}

.search {
  position: relative;

  & input {
    display: block;
    border: 2px solid #285EDE;
    border-radius: 4px;
    background: #F4F7FD;
    height: 48px;
    width: 100%;
    outline: none;
    padding: 0px 40px 0px 12px;
    box-sizing: border-box;

    font-family: "IBM Plex Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #262E52;
  }
  & input:placeholder-shown {
    font-weight: 600;
    color: #333;
  }

  & .clear {
    position: absolute;
    top: 50%;
    right: 8px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    margin-top: -16px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba(96, 79, 237, 0.10);
      border-radius: 50%;
    }
  }
}

.list {
  overflow-y: auto;
  flex-grow: 1;
  min-height: 250px;
  max-height: 340px;
  position: relative;

  & ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  & .shadow {
    width: 100%;
    height: 44px;
    background: linear-gradient(189deg, rgba(248, 248, 248, 0.00) 7.16%, rgba(0, 0, 0, 0.07) 102.92%);
    position: sticky;
    bottom: 0px;
  }
}

.item {
  border: 1px solid #FFF;
  border-bottom: 1px solid rgba(90, 97, 146, 0.20);
  cursor: pointer;
  text-decoration: none;
  display: block;
  display: flex;
  flex-flow: column;

  & .content {
    padding: 10px 12px 12px 12px;

    font-family: "IBM Plex Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    color: #5A6192;
    text-decoration: none;
    box-sizing: border-box;
  }

  & strong {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    color: #000;
  }

  & .cvr {
    white-space: nowrap;
  }

  & .top {
    display: flex;
    flex-flow: row;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 2px;
  }

  &:hover {
    border: 1px solid #0060E6;
    background: #F3F7FF;
    color: #0060E6;

    & strong {
      color: #0060E6;
    }
  }

  &.remembered {
    border: 1px solid rgba(90, 97, 146, 0.20);
    gap: 2px;
    margin-bottom: 16px;

    &:hover {
      border: 1px solid #0060E6;
    }

    & .label {
      padding: 4px 12px;
      background: #385BEA;
      align-self: flex-start;

      color: #FFF;
      font-family: "IBM Plex Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px; /* 146.154% */
      letter-spacing: -0.26px;
    }
  }
}

@keyframes spinner {
  0% {
    /* transform:  translate(0px, 0px) rotate(0deg); transform-origin: 0.5px 5.6px; */
    width: 1px;
  }
  50% {
    /* transform:  translate(0px, 0px) rotate(0deg); transform-origin: 108.5px 5.6px; */
    width: 217px;
  }
  100% {
    width: 1px;
  }
} 

.loader {
  min-height: 24.8rem;
  justify-content: center;
  align-items: center;

  & .spinner {
    width: 217px;

    & .bar {
      height: 12px;
      background: rgb(96, 79, 237);
      width: 1px;
      animation: spinner 3s linear 0s forwards infinite;
    }
  }

  color: #000;

  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px; /* 140% */
  letter-spacing: -0.48px;
}

.remember {
  color: #333;
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  display: flex;
  flex-flow: row;
  align-items: center;
  cursor: pointer;
  gap: 16px;

  & input {
    accent-color: rgba(40, 94, 222, 1);
    height: 16px;
    margin: 0px 0px 0px 10px;
  }
  & label {
    cursor: pointer;
  }
}