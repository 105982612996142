body {
    
}

#criipto_react_root, #storybook-root {
  height: 100%;
}

@media (min-width: 750px) {
  #criipto_react_root, #storybook-root {
    height: auto;
  }
}