.default-hidden, .sebankid .default-hidden {
    display: none;
}

.desktop-show {
    display: none;
}

@media (min-width: 750px) {
    .desktop-show, .sebankid .desktop-show {
        display: block;
    }

    span.desktop-show {
        display: inline;
    }
}

.is-android .is-android-show {
    display: inherit;
}

.is-android .is-android-hide {
    display: none;
}

.is-ios .is-ios-hide {
    display: none;
}

.is-not-ios .is-not-ios-show {
    display: block;
}

.has-error .has-error-hide, .has-error .is-android-show.has-error-hide {
    display: none;
}

.has-error .has-error-show {
    display: block;
}

.has-error span.has-error-show {
    display: inline;
}


body.sebankid {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.sebankid p {
    margin: 0px 0px 10px 0px;
}

.sebankid .frame {
    padding: 10px;
}

.sebankid .form-group label {
    margin-bottom: 10px;
}

.sebankid .form-group label, .sebankid .form-group .input-text {
    width: 100%;
    display: block
}

.sebankid .form-group .input-text {
    display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 1px;
    outline: none;
    box-sizing: border-box;
}

.sebankid .form-group .input-text:hover, .sebankid .form-group .input-text:focus {
    border-color: #727272;
}

.sebankid .form-group + .button {
    margin-top: 10px;
}

.sebankid .button {
    display: inline-block;
    cursor: pointer;
    font-weight: 400;
    padding: 10px 12px;
    border: 0;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.42857143;
    text-align: center;
    min-width: 160px;
    text-decoration: none;
}

.sebankid .button.button-primary {
    background: #225971;
    color: #FFF;
}

.sebankid .text-center {
    text-align: center;
}

.sebankid p.error {
    color: #D0021B;
}

.sebankid .form-group .input-text.invalid + .input-validation-error {
    display: inline;
    color: #D0021B;
    font-size: 75%;
}

.sebankid .frame .qr-code {
    width: 240px;
    height: 240px;
    padding-bottom: 10px;
}

.sebankid .sebankid-another-device-qr img, .sebankid .sebankid-another-device-qr canvas {
    width: 100%;
}

.sebankid .spinner,
.sebankid .spinner:after {
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.sebankid .spinner {
    margin: 0px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(34,89,113, 0.2);
    border-right: 1.1em solid rgba(34,89,113, 0.2);
    border-bottom: 1.1em solid rgba(34,89,113, 0.2);
    border-left: 1.1em solid #225971;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}