.label {
  font-family: "IBM Plex Sans",Arial,Helvetica,FreeSans,sans,sans-serif;

  display: inline-block;
  font-weight: 700;
  margin-bottom: 8px;
  font-size: .875rem;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: .2px;
  color: #333;
}

.body {
  font-family: "IBM Plex Sans",Arial,Helvetica,FreeSans,sans,sans-serif;
}

.cancel {
  font-family: "IBM Plex Sans",Arial,Helvetica,FreeSans,sans,sans-serif;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: #001c44;
  text-decoration: none;
}