.company-selector {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin: 0 0 10px 30px;
}

.dkmitid .company-selector h2 {
    font-size: 20px;
    font-weight: 100;
    border-bottom: solid 1px #d6d6d6;
    padding: 0 0 10px 0;
    margin: 0 0 20px 0;}

.dkmitid .company-selector .company-list ul {
    padding: 0;
}

.dkmitid .company-selector .company-list ul li {
    list-style: none;
    padding: 10px 0 0 30px;
}

.dkmitid .company-selector .company-list ul li:first-child {
    padding-top: 0;
}

.dkmitid .company-selector .company-list ul li input[type="radio"] {
    margin: 4px 0 0 -30px;
    float: left;
}

.dkmitid .company-selector .company-list ul li .note {
    color: grey;
}

.dkmitid .company-selector .left {
    float: left;
}

.dkmitid .company-selector .company-buttons {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #d6d6d6;
    padding-top: 12px;
}

.dkmitid .company-selector .company-button-continue {
    background-color: white;
    border-color: black;
    height: 36px;
    padding: 0 20px !important;
    margin: 0;
    min-width: 115px;
}

.dkmitid .company-selector .error {
    color: red
}

.dkmitid_cvrselect_api_error_warning {
    background-color: #fffedb;
    display: flex;
    padding: 10px 12px 12px 12px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    margin-bottom: 8px;
}

.dkmitid_cvrselect_api_error_warning svg {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
}

.dkmitid_cvrselect_api_error_warning p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 8px;
    flex: 1 0 0;
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.14px;
    color: #25262b;
    white-space: pre-wrap;
    margin-bottom: 0;
}