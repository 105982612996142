
.finland-selector {
  display: flex;
  flex-flow: column;
  gap: 8px;
}
  .finland-selector .finland-error {
    position: relative;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;

    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  .finland-selector .finland-bankid {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
  }

  .finland-selector .option {
    border: 1px solid #ccc;
    background: #FFF; /* Guard against colored backgrounds */
    height: 70px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 4px);
    box-sizing: border-box;
  }
  .finland-selector .option:focus-visible {
    border: 1px solid #999;
    outline: none;
  }
  .finland-selector .option.option-mpki-telia-1 {
    margin-top: 8px;
    text-decoration: none;
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    display: flex;
    flex-flow: row;
    gap: 8px;
  }

  .finland-selector .option.option-cancel {
    margin-top: 8px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    color: #000;
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    height: auto;
    width: auto;
    align-self: flex-start;
    padding: 8px 12px;
  }
    .finland-selector .option.option-cancel svg {
      width: 14px;
      height: 14px;
    }

  .finland-selector .option img {
    max-height: 34px;
    max-width: calc(100% - 65px);
  }

.finland-terms {
  max-width: 400px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 30px;
  text-align: center;
}