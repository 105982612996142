.criipto-eid-selector {
  display: flex;
  gap: 8px;
  flex-flow: column;
  max-width: none;
}

.criipto-eid-selector .criipto-qr-code {
  display: none;
  background: rgb(186 188 215);
  color: rgb(16 24 40);
  padding: 24px;
}
.criipto-eid-selector .criipto-qr-code p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: rgb(16 24 40);
  margin: 0;
}

.criipto-eid-selector .criipto-qr-code button {
  background: transparent;
  display: inline;
  color: rgb(16 24 40);
  text-decoration: underline;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

@media (min-width: 896px) {
  .criipto-eid-selector {
    padding: 0px;
  }
  .criipto-eid-selector .criipto-qr-code {
    display: block;
  } 
}

.criipto-eid-selector .criipto-qr-element {
  margin-top: 16px;
}

.criipto-eid-selector .criipto-eid-btn.criipto-cancel-btn {
  cursor: pointer;
  text-align: center;
  border: 1px solid #ccc;
  background: transparent;
  color: #000;
}

.criipto-eid-btn .criipto-eid-logo svg {
  max-width: 100%;
  max-height: 100%;
}